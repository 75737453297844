$mobile-width: 500px;
.sideNavContainer {
  text-align: left;
  grid-column: 1 / 2;
  height: max-content;
  position: sticky;
  top: 112px;
  @media (max-width: $mobile-width) {
    display: none;
  }
}

.feedContainer {
  grid-column: 2 / 3;

  height: max-content;
  &Heighted {
    height: 50vh;
  }
}

.heightedMiddleContainer {
  min-height: 110vh;
  width: 100%;
  @media (max-width: $mobile-width) {
    width: 100%;
  }
}
.bannerAdsContainer {
  grid-column: 3 / 4;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: var(--color-bg-white);
  padding: 0.5rem;
  position: sticky;
  top: 112px;
  @media (max-width: $mobile-width) {
    width: 100%;
  }
}

// Form styles
.formFieldError {
  border: 1px solid var(--color-fill-error) !important;
}

.formErrortext {
  color: var(--color-fill-error);
  font-weight: normal;
  margin-left: 0.5rem;
}

.formLabel {
  margin-bottom: 0.75rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formFieldSideContainer {
  display: flex;
  justify-content: space-between;

  & > .formField {
    width: 48%;
  }
}

.homeContainer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 11rem auto 20rem;
  max-width: 1320px;
  column-gap: 1rem;
  padding: 0 2rem;

  @media screen and (max-width: 800px) {
    grid-template-columns: 8rem auto 18rem;
  }
  @media screen and (max-width: $mobile-width) {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }
}

.middleContainer {
  min-height: max-content;
}

.profileInput {
  margin-bottom: 0;
}

.container {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: var(--color-bg-white);
  border-radius: var(--border-radius-4);
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

// For settings page
// Settings -- Ads
.settingsPageContainer {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 11rem auto;
  max-width: 1320px;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding: 0 2rem;
  @media (max-width: $mobile-width) {
    display: block;
    padding: 0 1rem;
  }
}

.authAppContainer {
  background-color: var(--color-bg-body);
}

.chat {
  &ListMessage {
    margin-bottom: 1rem;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 1rem !important;
  }

  &PrivateMessageList {
    border: none !important;
  }
}

.chatInboxContainer {
  position: sticky;
  height: 112px;
  height: max-content;
}

.imageToggleButton {
  margin-left: 1rem;
  display: none;
  @media (max-width: $mobile-width) {
    display: block;
  }
}

.imp-info {
  display: grid;
  grid-template-columns: 20px auto;
}

.red {
  border: 1px solid red !important;
  border-radius: 5px;
}

.terms {
  width: 900px;
  margin: auto;
  margin-top: 100px;
  padding-bottom: 100px;
  p {
    margin: 2rem 0;
  }
  ul {
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
  li {
    list-style: auto;
    margin-bottom: 5px;
  }
}
