.formField {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & input {
    pointer-events: auto;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-4);
    min-height: 3rem;
    margin: 0;
    text-decoration: none;
    vertical-align: middle;
    word-break: normal;
    background: transparent;
    border: 0;
    outline: 0;
    transition: color 0.24s;
    appearance: none;
    user-select: auto;
    display: inline-flex;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
    flex: 1 1 auto;
    width: 100%;
    text-align: left;
    cursor: text;
    text-transform: none;
    color: var(--color-secondary-1);
    font-weight: 400;
    font-size: 0.9rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    &:focus,
    &:focus + &:hover {
      border: 1px solid var(--color-secondary-1);
      // border: 1px solid var(--color-input-border-1);
    }

    &:hover {
      border: 1px solid var(--color-secondary-1);
      // border: 1px solid var(--color-input-border-1);
    }
  }
}

.formFieldError {
  border: 1px solid var(--color-fill-error) !important;
}

.formErrortext {
  color: var(--color-fill-error);
  font-weight: normal;
  margin-left: 0.5rem;
}

.formLabel {
  margin-bottom: 0.75rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.errorLabel {
  color: var(--color-fill-error);
}

.halfWidth {
  width: 48%;
}

.thirdWidth {
  width: 30%;
}
.thirdWidth {
  width: 30%;
}
