.container {
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: var(--color-bg-white);
  padding: 1rem 1.5rem;
}

.clickContainer {
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr;
  }
}

.createContainer {
  margin-left: 0.5rem;
  width: 100%;
  cursor: pointer;
  background-color: var(--color-secondary-1-opacity-2);
  border-radius: 3rem;
  padding: 0.5rem 1rem;

  & h3 {
    opacity: 0.75;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.footerButton {
  margin-right: 0.75rem;
  padding: 0;
  font-size: 1.25rem;
}
