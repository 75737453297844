html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100% !important;

  // @media only screen and (max-width: 880px) {
  //   font-size: 80%;
  // }
  // @media only screen and (max-width: 750px) {
  //   font-size: 75%;
  // }
  // @media only screen and (max-width: 700px) {
  //   font-size: 70%;
  // }
  // @media only screen and (max-width: 500px) {
  //   font-size: 65%;
  // }
  // @media only screen and (max-width: 400px) {
  //   font-size: 60%;
  // }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html * {
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--font-base);
  background-color: var(--color-bg-1);
}

a,
a:link,
a:visited {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  border: none;
}

textarea {
  resize: none;
}

// * {
//   outline: 1px solid red;
// }
