@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500&display=swap");

:root {
  // --font-base: "Inter", sans-serif;
  --font-base: "Noto Sans JP", sans-serif;

  --border-radius-4: 4px;

  --color-fill-error: #f64e60;

  --color-primary-1: #3385ff;
  // --color-primary-2: #2f57f1;
  --color-primary-2: #180f77;

  --color-input-border-1: #40a9ff;

  --color-secondary-1: #3c4257;
  --color-secondary-1-opacity-1: rgba(60, 66, 87, 0.04);
  --color-secondary-1-opacity-2: hsla(231, 8%, 65%, 0.1);

  --color-bg-white: #fff;
  // --color-bg-1: #f7fafc;
  --color-bg-1: #f0f0f0;
  // --color-bg-body: #e0e0e0;
  --color-bg-body: #f0f0f0;
}
