.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 5px;
}
.user:hover {
  background-color: rgba(233, 233, 233, 0.5);
}
