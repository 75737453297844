#nprogress .bar {
  z-index: 5000 !important;
  height: 3px !important;
  background: var(--color-primary-1);
}

#nprogress .peg {
  display: none !important;
}

/* antd overrides */
// Custom border-radius
.ant-btn,
.ant-tag,
.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-popover-inner,
.ant-select-selector,
.ant-select,
.ant-message,
.ant-message-notice-content,
.ant-tooltip,
.ant-tooltip-placement-left,
.ant-tooltip-content,
.ant-tooltip-inner,
.ant-modal-content {
  border-radius: var(--border-radius-4);
}

// .ant-btn-primary:hover,
// .ant-btn-primary:focus
.ant-btn {
  border: none;
}
.ant-btn-primary {
  background: var(--color-primary-2);

  &:hover,
  &:focus {
    color: #fff;
    background: var(--color-primary-2);
    opacity: 0.75;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

//  Navbar Search dropdown: Select Overrides
.search-bar {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: var(--border-radius-4) !important;
    background-color: var(--color-secondary-1-opacity-2);
    // padding: 0.25rem 1.5rem;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    font-size: 1rem;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-top: 4px;
  }
  // To display search icon
  .ant-select-arrow svg {
    display: inline-block;
    font-size: 1rem;
    margin-top: -3px;
  }
}

//  Form Select dropdown: Select Overrides
.select-form-input {
  display: flex;
  flex-direction: column;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 3rem !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid transparent !important;
    border-radius: var(--border-radius-4) !important;
    background-color: var(--color-secondary-1-opacity-2);
    color: var(--color-secondary-1);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    &:focus,
    &:focus + &:hover {
      border: 1px solid var(--color-secondary-1) !important;
      border-radius: var(--border-radius-4) !important;
    }

    &:hover {
      border: 1px solid var(--color-secondary-1) !important;
      border-radius: var(--border-radius-4) !important;
    }
    // padding: 0.25rem 1.5rem;
    padding-top: 6px;
    padding-bottom: 4px;
    border: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-top: 4px;
  }
  // To display search icon
  .ant-select-arrow svg {
    display: inline-block;
    font-size: 0.75rem;
    // margin-top: -5px;
  }
}

.ant-cascader-menu {
  height: max-content;
  overflow: visible;
  width: max-content;
}

.cascade-form-input {
  display: flex;
  flex-direction: column;

  .ant-cascader-picker ant-cascader-picker-show-search {
    width: 100%;
    height: 3rem !important;
  }

  .ant-cascader-input.ant-input {
    height: 3rem;
  }

  .ant-cascader-menu {
    height: max-content;
    overflow: visible;
    width: max-content;
  }

  .ant-cascader-menu:first-child {
    border-radius: 4px 0 0 4px;
  }

  .ant-cascader-menu:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.ant-picker {
  border-radius: 4px;
  height: 3rem;
}
.ant-picker-panel-container {
  border-radius: 4px;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: none;
  border-right-width: 0;
}
.ant-picker-focused {
  border-color: none;
  border-right-width: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.prof-doc-upload {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
  }
  .ant-upload-list {
    width: 100%;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
  }
  .ant-upload-list-item-error {
    border-color: lightgray !important;
  }
}

.ant-input:hover,
.ant-input:focus,
textarea.ant-input,
.ant-input {
  border-radius: 4px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.ant-input:hover,
.ant-input:focus {
  border: 1px solid #1890ff;
}

textarea.ant-input {
  // min-height: 10rem;
}

.post-action-btn.ant-btn:hover,
.post-action-btn.ant-btn:focus {
  color: inherit;
  background: #ececec;
  border: none;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

// .post-action-btn.ant-btn:focus {
//   background: inherit;
//   border: none;
//   box-shadow: none;
// }

// Profile Heade tab changes
.profile-action-btn.ant-btn {
  background: #fff;
}

.profile-action-btn.ant-btn:hover {
  color: inherit;
  background: #e0e0e0;
  border: none;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.profile-action-btn.ant-btn:focus {
  border: none;
  background-color: #e0e0e0;
  color: inherit;
  box-shadow: 0 0 0 1px var(--color-primary-2);
}

// Remove right paddiong of horizontal divider text
.profile-overview {
  .ant-divider-inner-text {
    padding-left: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0%;
  }
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 0.5rem 1rem;
  padding-left: 0.5rem;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-progress-inner {
  background-color: #ddd;
}

/* Chat Library Overrides */
.cs-main-container *,
.cs-conversation-list * {
  font-family: var(--font-base) !important;
}

.cs-message--incoming .cs-message__content,
.cs-message-group--incoming
  .cs-message-group__messages
  .cs-message
  .cs-message__content {
  background-color: #faf8f7 !important;
}
.cs-message--outgoing .cs-message__content,
.cs-message-group--outgoing
  .cs-message-group__messages
  .cs-message
  .cs-message__content {
  background-color: #ecf9fd !important;
}
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container,
.cs-message-input__content-editor {
  background-color: #fff;
}

/* HASHTAGS */
.hashtag-textarea__control {
  margin-bottom: 1.25rem;
}
.hashtag-textarea__input {
  background-color: #fff;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  padding: 0.75rem;
  font-size: 1rem;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid #eee;
  &:focus,
  &:active {
    outline: none;
    border: 1px solid #3fb9de;
  }
  &::placeholder {
    color: #a4b0be;
    font-size: 14px;
  }
}
.hashtag-textarea__highlighter {
  padding: 0.75rem;
}
.hashtag-textarea__suggestions__list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}
.hashtag-textarea__suggestions__item {
  padding: 0.25rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.hashtag-textarea__suggestions__item — focused {
  background-color: #daf4fa;
}

.hashtag-contentarea {
  textarea {
    border: none;
    opacity: 1;
    color: black;
  }
  border: none;
}

/* Override antd modal header */
.ant-modal-close,
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #fff;
}
.ant-modal-header {
  background: var(--color-primary-2);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}
.ant-modal-title {
  color: #fff;
}

/* Make antd accordion header text bolder */
.ant-collapse-header {
  font-weight: 500;
}

// React Date picker Override Styles
.react-date-picker {
  width: 100%;
  height: 3.1rem;
}
.react-date-picker__wrapper {
  border: 1px solid #e7e7ea !important;
  border-radius: 0.2rem;
  z-index: 8 !important;
}
.react-date-picker__inputGroup {
  margin-left: 0.2rem;
}
.ant-slider {
  .ant-slider-track {
    background-color: var(--color-primary-1);
    &:hover {
      background-color: var(--color-primary-1);
    }
  }
  .ant-slider-handle {
    border-color: var(--color-primary-2);
  }
}
.react-date-picker__calendar {
  width: 283px !important;
}

.ant-checkbox {
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--color-primary-2);
      border-color: var(--color-primary-2);
    }
  }
}
// .ant-checkbox-inner {
//   background-color: var(--color-primary-2);
// }

.ant-picker:hover,
.ant-picker-focused {
  border-color: black !important;
}

.ant-tooltip {
  z-index: 1060 !important;
}
