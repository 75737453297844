.navContainer {
  background-color: var(--color-bg-white);
  height: 6rem;
  margin: 0 auto;
  padding-top: 1.15rem;
  position: sticky;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  top: 0;
  z-index: 50;
  box-shadow: 0px 2px 6px -2px #c0c0c09e;

  @media screen and (max-width: 600px) {
    height: 4rem;
    padding-top: 0.75rem;
  }
}

.navbar {
  display: grid;
  grid-template-columns: 12rem auto auto auto;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2.5rem;
  background-color: var(--color-bg-white);

  @media screen and (max-width: 1100px) {
    grid-template-columns: 8rem auto auto auto;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 5rem auto auto auto;
  }

  @media screen and (max-width: 860px) {
    padding: 0 1.5rem;
    grid-template-columns: 5rem 20rem auto auto;
  }

  @media screen and (max-width: 600px) {
    padding: 0 0.5rem;
    grid-template-columns: 4rem 16rem auto auto;
  }
  @media screen and (max-width: 480px) {
    max-width: 100%;
    grid-template-columns: 15% 40% 20% 25%;
  }
  @media screen and (max-width: 330px) {
    max-width: 100%;
    grid-template-columns: 15% 40% 20% 25%;
  }
}

.logo {
  width: 4rem;
  margin-right: auto;

  @media screen and (max-width: 600px) {
    width: 2.5rem;
  }
  @media screen and (max-width: 450px) {
    width: 2rem;
  }
}

.logoLink {
  grid-column: 1 / 2;
}

.searchContainer {
  grid-column: 2 / 3;
  align-self: auto;
  display: flex;
}

.usedSpaceContainer {
  grid-column: 3 / 4;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 750px) {
    display: none;
  }
}

.navRight {
  grid-column: 4 / 5;
  align-self: auto;
  justify-self: end;
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px) {
    grid-column: 5 / 3;
    // grid-column: unset;
    // display: grid;
    // grid-template-columns: 1fr 3fr 1fr;
    justify-self: center;
    justify-content: center;
    gap: 5px;
  }
}

.profileLink {
  @media screen and (max-width: 960px) {
    display: none;
  }
}
.notificationIcon {
  margin-left: 1.25rem;
  @media screen and (max-width: 500px) {
    margin-left: 0.3rem;
  }
}

.userAvatar {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  // border-right: 1px solid var(--color-secondary-1);
}

.accountMenu {
  width: max-content;
}

.accountMenuItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.35rem;

  &:hover {
    background-color: var(--color-secondary-1-opacity-1);
    border-radius: var(--border-radius-4);
    & p,
    & .accountMenuIcon {
      font-weight: 600;
      opacity: 1;
    }
  }
  & p {
    margin-left: 0.5rem;
  }
}

.accountMenuIcon {
  font-size: 1rem;
  opacity: 0.4;
}

.accountBtn {
  margin-top: 0.25rem;
}

.notificationMenu {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 20rem;

  & h2 {
    margin-bottom: 1.25rem;
  }
}
.notificationItem {
  display: grid;
  grid-template-columns: 40px auto;
  margin-bottom: 1rem;
  padding: 0.3rem;
  border-radius: 5px;
}
.notificationItemTitle {
  font-weight: 500;
  color: black;
}

.seen {
  font-weight: 400;
  color: gray;
}
.notificationItem:hover {
  background-color: rgba(233, 233, 233, 0.5);
}
.usageText {
  span {
    font-size: 0.6rem;
  }
}
