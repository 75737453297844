.videoPlayer {
  margin-top: 1rem;
}

.videoCard {
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: var(--border-radius-4);
  margin-bottom: 1rem;

  &:hover {
    // border: 1px solid rgba(0, 0, 0, 0.75);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}

.videoThumbnail {
  border-radius: 4px 4px 0 0;
  background-color: #eee;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.videoDetail {
  padding: 0 0.5rem;
  flex-basis: 30%;
  padding-bottom: 0.75rem;
  padding-top: 1rem;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.playIcon {
  font-size: 3rem;
}
