.title {
  // margin-bottom: 0.75rem;
  cursor: pointer;
}

.topicContainer {
  margin-bottom: 1rem;
  background-color: var(--color-bg-white);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border: 2px solid rgba(24, 15, 119, 0.5);
  padding: 1rem 1.25rem;
}
.active {
  background-color: rgb(24, 15, 119);
  h2 {
    color: #fff !important;
  }
}
.topicTitle {
  cursor: pointer;
  text-transform: uppercase;
  // opacity: 0.5;
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  letter-spacing: 1px;
}
.topicVideo {
  font-size: 1.025rem;
}
.topicVideoContainer {
  > h2 {
    color: #fff !important;
  }
}

.topic {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  cursor: pointer;
  background-color: var(--color-secondary-1-opacity-1);
  padding-left: 0.25rem;
  &:hover {
    border-radius: var(--border-radius-4);

    & p,
    & .accountMenuIcon {
      font-weight: 600;
      opacity: 1;
    }
  }

  & p {
    margin-left: 0.5rem;
  }
}
