.banner {
  // flex-basis: 50px;
  width: 100%;
  height: 50px;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  & img {
    width: 100%;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.bannersContainer {
  // padding: 1rem;
  @media (max-width: 500px) {
    position: sticky;
    bottom: 10px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    max-height: 125px;
    overflow: hidden;
    box-shadow: 0px 0px 6px -1px rgba(212, 212, 212, 1);
  }
  max-height: 592px;
  height: max-content;
  display: flex;
  flex-direction: column;
}
