.rightContainer {
  grid-column: 3 / 4;
  justify-self: end;
  display: flex;
  align-items: center;
}

.userFullName {
  font-size: 1.1rem;
  margin-right: 1.25rem;
}

.navContainer {
  background-color: var(--color-bg-white);
  height: 5rem;
  margin: 0 auto;
  padding-top: 18px;
  position: sticky;
  top: 0;
  z-index: 1;
  // box-shadow: 0 5px 7px 0px rgba(0, 0, 0, 0.1),
  //   0 1px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 5px #efefef;
}

.navbar {
  display: grid;
  grid-template-columns: 15.5rem auto auto;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2.5rem;
}

.logo {
  width: 2.75rem;
  margin-right: auto;
}

.logoLink {
  grid-column: 1 / 2;
}

.searchContainer {
  grid-column: 2 / 3;
  align-self: auto;
  display: flex;
  justify-content: center;
}

.navRight {
  grid-column: 3 / 4;
  align-self: auto;
  justify-self: end;

  display: flex;
  align-items: center;
}

.userAvatar {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  // border-right: 1px solid var(--color-secondary-1);
}

.accountMenu {
  width: 10rem;
}

.accountMenuItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem 0.35rem;

  &:hover {
    background-color: var(--color-secondary-1-opacity-1);
    border-radius: var(--border-radius-4);

    & p,
    & .accountMenuIcon {
      font-weight: 600;
      opacity: 1;
    }
  }

  & p {
    margin-left: 0.5rem;
  }
}

.accountMenuIcon {
  font-size: 1rem;
  opacity: 0.4;
}

.accountBtn {
  margin-top: 0.25rem;
}

.notificationMenu {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 20rem;

  & h2 {
    margin-bottom: 1.25rem;
  }
}
.notificationItem {
  display: flex;
  margin-bottom: 0.5rem;
}
