/* Spin the icon for loader */
.spinner {
  animation: spin infinite 0.5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
