.container {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: var(--color-bg-white);
  border-radius: var(--border-radius-4);
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.postTop {
  display: flex;
  margin-bottom: 1.5rem;
}

.postUser {
  margin-right: auto;
}
.username {
  font-weight: 500;
}

.postSubHeader {
  display: flex;
}

.postTime {
  opacity: 0.9;
  font-size: 0.8rem;
}
.postText {
  line-height: 1.75;
}

.postImage {
  width: 100%;
  object-fit: contain;
  border-radius: var(--border-radius-4);
  margin-top: 1rem;
}

.divider {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  opacity: 0.1;
  color: #ddd;
}

.postActions {
  display: flex;
  justify-content: space-between;
  // padding: 0 5vw;
}
.action {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  // margin-right: 3rem;
  cursor: pointer;
  p {
    font-size: 1rem;
  }
}

.actionIcon {
  font-size: 1rem;
}

.liked {
  fill: #2f57f1;
}

.postStats {
  display: flex;
  align-items: center;
  // margin-top: 1rem;
}

.stat {
  display: flex;
  align-items: center;

  & p {
    margin-left: 0.25rem;
  }
  & span {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.postMenuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.25rem;
  padding-right: 0.35rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  & p {
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: var(--color-secondary-1-opacity-1);
    border-radius: var(--border-radius-4);

    & p,
    & .accountMenuIcon {
      // font-weight: 500;
      opacity: 1;
    }
  }
}

.postFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  & p {
    font-size: 0.9rem;
  }
}
