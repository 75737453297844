.outerContainer {
  padding-bottom: 1.5rem;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  // align-items: center;

  a {
    flex-basis: 50%;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  p {
    font-size: 1.25rem;
  }
}

.detailIcon {
  opacity: 0.5;
  font-size: 4rem;
  margin-bottom: 1rem;
}
