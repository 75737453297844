#dropdown-date {
  display: flex;
  justify-content: space-between;
}

#select-year,
#select-month,
#select-day {
  height: 48px;
  border: 1px solid #e0e1e4;
  background-color: white;
  border-radius: var(--border-radius-4) !important;
  text-align: center;

  // box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
  //   0 5px 15px 0 rgba(0, 0, 0, 0.12);
}
#select-month {
  max-width: 7rem;
  display: flex;
  justify-content: center;
  padding-left: 0.5rem;
}
#select-day {
  padding-left: 0.4rem;
  max-width: 7rem;
}
#select-year {
  padding-left: 0.2rem;
  max-width: 6rem;
}
.monthOptions {
  text-align: center !important;
  display: flex;
  justify-content: center;
}
.monthContainer {
  // text-align: center !important;
}

.about-edit {
  #select-year {
    max-width: 4.5rem;
    margin-right: 0.1rem;
  }
  #select-month {
    max-width: 5rem;
  }
  // #dropdown-day {
  //   max-width: 0.2rem;
  // }
}

.react-date-picker__wrapper {
  z-index: 0 !important;
}
