/* LAYOUT */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.flex-column {
  flex-direction: column;
}
.b-rad-4 {
  border-radius: var(--border-radius-4);
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-6 {
  margin-bottom: 8px;
}

.desktop-only {
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
}
.mobile-only {
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
}
.w-100 {
  width: 100%;
}
.overflow-auto {
  overflow: auto;
}
