.locale {
  &Container {
    margin-left: 1.5rem;
  }
  &Current {
    width: 1.5rem;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: var(--border-radius-4);
  }
  &Flag {
    width: 1.5rem;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: var(--border-radius-4);
  }

  &FlagContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    & img {
      margin-right: 0.5rem;
    }
  }
}
.localeContainer {
  @media (max-width: 500px) {
    display: none;
  }
}
