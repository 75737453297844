.labelActionBtn {
  margin-right: 0.1rem;
  border-radius: 4px;
  padding: 1px 5px;
  cursor: pointer;
}

.labelActionBtn:hover {
  text-align: center;
  vertical-align: center;
  background-color: #eee;

  .labelActionIcon {
    opacity: 1;
  }
}

.labelActionIcon {
  opacity: 0.75;
}
