.container {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: var(--color-bg-white);
  border-radius: var(--border-radius-4);
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 500px) {
    max-width: 100%;
    padding: 1rem;
  }
}

.videoCard {
  width: 12rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: var(--border-radius-4);
  margin-bottom: 1rem;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.75);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    // box-shadow: rgba(0, 0, 0, 0) 0px 3px 6px 0px;
    transform: scale(1.05);
  }
}

.videoThumbnail {
  border-radius: 4px 4px 0 0;
  background-color: #eee;
  flex-basis: 70%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.videoDetail {
  text-transform: capitalize;
  padding: 0 0.5rem;
  flex-basis: 30%;
  padding-bottom: 0.75rem;
  padding-top: 1rem;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.scrollContainer {
  max-width: 37rem;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  padding: 1rem 0.5rem;

  @media screen and (max-width: 1370px) {
    max-width: 50vw;
  }
  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
}

.similarCardContainer {
  margin-right: 1.5rem;
  & p {
    font-weight: 500;
  }
}

.playIcon {
  font-size: 2rem;
}

.title {
  text-transform: uppercase;
  opacity: 0.75;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  padding-left: 0.5rem;
}

.postTime {
  opacity: 0.9;
  font-size: 0.8rem;
}
